import React from 'react'

const Conservacion = () => {
  return (
    <div>
      Estamos en conservacion
    </div>
  )
}

export default Conservacion
