export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_LOCALSTORAGE_REQUEST = 'USER_LOCALSTORAGE_REQUEST'
export const USER_LOCALSTORAGE_SUCESS = 'USER_LOCALSTORAGE_SUCESS'
export const USER_LOCALSTORAGE_NOT_FOUND = 'USER_LOCALSTORAGE_NOT_FOUND'

