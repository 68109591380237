import React from 'react'

const Recaudo = () => {
  return (
    <div>
      Recaudo
    </div>
  )
}

export default Recaudo
